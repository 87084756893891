import React, { useEffect, useState } from "react";
import "../css/MarketingBanner.css";
import { useSelector } from "react-redux";
import RichText from "./RichText";

const MarketingBanner = () => {
  const [isOpen, setIsOpen] = useState(false);
  // access our data so that we can use it
  const { data } = useSelector((state) => state.cms);

  const singleTypeSlug = "navbar";
  const contentSlug = "pop-up";
  const navData = data[singleTypeSlug] && data[singleTypeSlug].attributes;
  const content = data[contentSlug] && data[contentSlug].attributes;

  //   open after 2 seconds of page load
  useEffect(() => {
    document.addEventListener("keydown", closeBanner, false);
    if (localStorage.getItem("nct_banner") !== "viewed") {
      openBanner();
    }

    return () => {
      document.removeEventListener("keydown", closeBanner, false);
    };
  }, []);

  const openBanner = () => {
    setTimeout(() => {
      setIsOpen(true);
      localStorage.setItem("nct_banner", "viewed");
    }, 2000);
  };

  const closeBanner = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  const renderContent = () => {
    if (isOpen)
      return (
        <>
          <img src={navData?.logo?.data?.attributes?.url} loading="lazy" alt="" className="logoicon" />
          {content?.heading && <h1 style={{ color: "#f06b24", textAlign: "center" }}>{content.heading}</h1>}
          {content?.body && <RichText text={content.body} left={false} />}
          {/* <p style={{ textAlign: "center", fontSize: "16px" }}>
            Now is the time to take advantage of incredible savings on quartz and granite countertops with Niagara Countertops and Wilsonart Canada’s Spring Refresh Sale. From April 27th to May 11th,
            pay only $10 a square foot on select brand-name, high-quality stone. Upgrade and transform your kitchen or bathroom, with these unbelievable sale prices at Niagara Countertops.
          </p>
          <p style={{ textAlign: "center", fontSize: "16px" }}>Hurry in for the best selection and while quantities last.</p> */}
          {content?.ctaPreHeading && (
            <h4 style={{ textAlign: "center", marginTop: "40px", marginBottom: 0 }}>
              {content.ctaPreHeading}
              <br />
            </h4>
          )}
          {content?.ctaHeading && <h2 style={{ color: "#22223b", textAlign: "center" }}>{content.ctaHeading}</h2>}
        </>
      );

    return <span>{content?.buttonText || "SALE!"}</span>;
  };

  if (!content) return null;
  return (
    <div onClick={() => setIsOpen(!isOpen)} className={`marketing-banner ${isOpen ? "open" : "closed"}`}>
      <div onClick={(e) => isOpen && e.stopPropagation()} className="inner">
        {renderContent()}
      </div>
      <img src="/images/x.png" alt="close" className="closeIcon" onClick={closeBanner} />
    </div>
  );
};

export default MarketingBanner;

// Spring Refresh Sale!

// Now is the time to take advantage of incredible savings on quartz and granite countertops with Niagara Countertops and Wilsonart Canada’s Spring Refresh Sale. From April 27th to May 11th, pay only $10 a square foot on select brand-name, high-quality stone. Upgrade and transform your kitchen or bathroom, with these unbelievable sale prices at Niagara Countertops.
// Hurry in for the best selection and while quantities last

// Visit Niagara Countertops today.
// 268 Plymouth Road Welland
