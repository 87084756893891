// Our Footer component
// Thsi will need to be modified to match the JSX coming from webflow

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

function Footer() {
  // access our data so that we can use it
  const { data } = useSelector((state) => state.cms);
  const singleTypeSlug = "footer";
  const footerData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!footerData) return null;

  return (
    <>
      {/* <div className="section-6 wf-section">
        <div className="container-4 w-container">
          <h2 className="heading2-subtle">{footerData.preFooterHeading}</h2>
          <a href={footerData.preFooterButton?.link} target="_blank" className="button shadow contractor w-button">
            {footerData.preFooterButton?.text}
          </a>
        </div>
      </div> */}
      <div className="footer wf-section">
        <div className="footermain shadow">
          <div className="container w-container">
            <Link to="/" aria-current="page" className="w-inline-block w--current">
              <img src={footerData?.logo?.data?.attributes?.url} loading="lazy" alt="" className="footerlogo" />
            </Link>
            {footerData?.socials?.map((el) => {
              return (
                <a key={uuid()} href={el.link} target="_blank" className="w-inline-block">
                  <img src={el?.image?.data?.attributes?.url} loading="lazy" alt="" className="socialicon" />
                </a>
              );
            })}

            <Link to={footerData.cta.link} className="button-outline footerlink w-inline-block">
              {footerData.cta.text}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
