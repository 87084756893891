// DOCUMENTATION:
// https://reactrouter.com/docs/en/v6

// This file sets up the page navigation or the sitemap of our website.
// This is not our Navbar component

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

// Import the necessary page files from ../pages/Something.js
import IndexPage from "../pages/IndexPage";
import ErrorPage from "../pages/ErrorPage";
import ContactPage from "../pages/ContactPage";
import ProductsPage from "../pages/ProductsPage";
import DealerPage from "../pages/DealerPage";
import MobileShowroomPage from "../pages/MobileShowroomPage";
import ProductDetailPage from "../pages/ProductDetailPage";

function Navigation(props) {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* We need to add a route for each of our pages. 
          Path is the page slug www.something.com/slug. 
          Element is the React component to display when we get to that slug. */}
          <Route path="/" element={<IndexPage />} />
          <Route path="/dealers" element={<DealerPage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/products/:id" element={<ProductDetailPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/mobile-showroom" element={<MobileShowroomPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default Navigation;
